import react from 'react';

// Function that takes a Button ref and toggles a loading state
export const toggleButtonLoading = (ref: react.RefObject<HTMLButtonElement>, state: "loading" | "default", defaultText: String = "") => {
    if (ref.current) {
        if (state === "loading") {
            ref.current.disabled = true;
            ref.current.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Loading...";
        } else if(state === "default") {
            ref.current.disabled = false;
            ref.current.innerHTML = defaultText.toString();
        }
    } 
};
export function fixedEncodeURIComponent(str: string) { return encodeURIComponent(str).replace(/[!'()*]/g, function(c) { return '%' + c.charCodeAt(0).toString(16); }); }
