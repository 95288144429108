import { API_URL } from "./components/Globals/globalVariables";

// code is based on https://stackoverflow.com/questions/2144386/how-to-delete-a-cookie
class cookiehandler {
    static delete_cookie(name: string, path: string, domain: string): void {
        if( this.getCookie( name ) ) {
          document.cookie = name + "=" +
            ((path) ? ";path="+path:"") +
            ((domain)?";domain="+domain.replace("dev","").replace("app.","").replace("twofa.",""):"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT;SameSite=None;Secure=true";
        }
    }
    static getCookie(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        var cookie: string | undefined = "";
        if (parts.length === 2) cookie = parts.pop()?.split(';').shift();
        if(cookie === undefined) return ""; else return cookie;
    }

    static setCookie(name: String, value: String, path: String, domain: string, expiresIn = (7 * 24 * 60 * 60 * 1000), secure = true, sameSite = "None"){
        const date = new Date();
        // Set it expire date - default is in 7 days
        date.setTime(date.getTime() + expiresIn);
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=" + path + "; domain=" + domain.replace("dev","").replace("app.","").replace("twofa.","").replace("www.","") + "; SameSite=" + sameSite + "; Secure=" + secure;            
    }

    static validate_ApiTokenUid = async (apikey: string,
                                  setIsLoggedInFunction: (_isLoggedIn: Boolean) => void,
                                  setApikey: (_apikey: string) => void,
                                  setUserID: (_uid: string) => void,
                                  setUsername: (_username: string) => void,
                                  setAccountType: (_accountType: "sub" | "main") => void,
                                  setFullName: (_fullName: string) => void) => {
        // request the current budget to validate the apikey
        let response = await fetch(API_URL + 'user', {method:'GET', 
            headers: {'apikey': apikey.toString()}});
        if(!response.ok){
            setIsLoggedInFunction(false);
            setApikey("");
            setUserID("");
            setUsername("");
        } else {
            let data = await response.json();
            // console.log("response: ", data);
            setIsLoggedInFunction(true);
            setApikey(apikey);
            setUserID(data.user.userId);
            setAccountType(data.user.accountType);
            setFullName(data.user.fullName);
            setUsername(data.user.userName);
        }
    }
}

export default cookiehandler;