import React, {useEffect, useState} from "react";
import _translate from "../Globals/translation";
import {twofaSite} from "./twoFA-Variables";

interface twoFAHeaderParameters {
    apikey: String; // API Key of the user - currently not used
    userID: String; // ID of the user - currently not used
    twofaSites: Array<twofaSite>; // Array of all TwoFA Sites
    prevSelectedSite: twofaSite | undefined; // The previously selected site - used to set the selected site after the sites are reloaded 
    setSelectedSite: (site: twofaSite | undefined) => void; // Function to set the selected site
    onClickDeleteSite: () => void; // Function to delete the selected site
    onClickStatistics: () => void; // Function to show the statistics of the selected site
    onClickSettings: () => void; // Function to show the settings of the selected site
}

const TwoFAHeader:React.FC<twoFAHeaderParameters> = ({apikey, userID, prevSelectedSite, twofaSites, onClickDeleteSite, setSelectedSite, onClickSettings, onClickStatistics}) => {
    const selectedSiteRef = React.useRef<HTMLSelectElement>(null); // Reference to the select element to set the selected site

    useEffect(() => {  // Update the selected site if the sites are reloaded
        if(selectedSiteRef.current && twofaSites !== undefined && twofaSites.length > 0){ // If the select element exists and there are sites
            if(prevSelectedSite !== undefined && prevSelectedSite.id !== undefined && prevSelectedSite.id !== ""){ // If there is a previously selected site
                selectedSiteRef.current.value = prevSelectedSite.id.toString(); // Set the selected site to the previously selected site
                setSelectedSite(prevSelectedSite); // Set the selected site to the previously selected site
            } else { // If there is no previously selected site but there are sites
                selectedSiteRef.current.value = twofaSites[0].id.toString(); // Set the selected site to the first site
                setSelectedSite(twofaSites[0]); // Set the selected site to the first site
            }
        } else if (selectedSiteRef.current && (twofaSites === undefined || twofaSites.length === 0)){
            setSelectedSite(undefined); // Set the selected site to undefined if there are no sites available
            selectedSiteRef.current.value = "0"; // Set the selected site to "no sites available"
        } else {
            setSelectedSite(undefined); // Set the selected site to undefined if there are no sites available and the select element does not exist
        }
    }, [twofaSites]); // Update the selected site if the sites are reloaded

    const onChangeSite = (e: React.FormEvent<HTMLSelectElement>) => { // Function to set the selected site
        const value = e.currentTarget.value; // Get the value of the select element
        const _twofaSite = twofaSites.find((site) => site.id.toString() === value); // Find the site with the selected id
        setSelectedSite(_twofaSite); // Set the selected site
    }
    const onClickAddSite = () => { // Function to add a new site
        setSelectedSite({ // Set the selected site to a blank site
            id: "", 
            twofaType: "optin(sms)",
            twofaLabel: "",
            twofaSitekey: "",
            twofaSecretkey: "",
            domains: [],
            captchaType: "reCaptcha",
            captchaSitekey: "",
            captchaSecretkey: ""
        });
        if(selectedSiteRef.current){ // If the select element exists
            selectedSiteRef.current.value = "empty"; // Set the selected site to the blank site (normally hidden option because the blank site is not in the list)
        }
    }

    return <div className='twofaHeader'> 
            <div className="twofaHeader-items">
                <div className="twoFA-siteselection-container">
                    <label className="descr-label label-sites-select" >{_translate("Choose the sites you want to edit")}</label>
                    <select  ref={selectedSiteRef} className="selectionelement select-sites" name="sites" id="sites" onChange={onChangeSite} defaultValue="">
                        <option className="site-option defaut-site-option-disabled hidden" value="empty" disabled></option>
                        {   // Map all sites to options in the select element
                            twofaSites.map((site, i) => {
                            return (
                                <option className="site-option" id={i.toString()} key={i.toString()} value={site.id}>{site.twofaLabel}</option>
                            );
                        })}
                        { // If there are no sites available, show a disabled option to inform the user 
                        twofaSites.length === 0 && <option className="site-option" id="0" key="0" value="0" disabled>{_translate("No sites available")}</option> }    
                    </select>
                </div>
                
                <div className="twoFA-button-container">
                    {selectedSiteRef.current?.value !== "empty" ? <button className="button twofa-button-addsite" onClick={onClickAddSite}>{_translate("Add Entry")}</button>: <></>}
                    {selectedSiteRef.current?.value !== "empty" && selectedSiteRef.current?.value !== "0" ? <button className="button twofa-button-delete" onClick={onClickDeleteSite}>{_translate("Delete Entry")}</button> : <></>}
                    {/* <button className="button twofa-button-statistics" onClick={onClickStatistics} disabled>{_translate("Statistics")}</button>
                    <button className="button twofa-button-settings" onClick={onClickSettings} disabled>{_translate("Settings")}</button> */}
                </div>
            </div> 
        </div> 
}

export default TwoFAHeader