import React, {useEffect, useState} from "react";
import _translate, { convertFloatSeperationToString, _getEventText } from "../Globals/translation"
import { API_URL } from "../Globals/globalVariables";

interface parameters {
    apikey: string;
    username: string;
    fullName: string;
    userID: string;
    accountType: "sub" | "main";
    event: string|undefined;
}

const Dashboard: React.FC<parameters> = ({apikey, userID, fullName, username, accountType, event}) => {
    const [budget, setBudget] = useState<String>("");

    // getting user informations    
    const get_buget = async () => {
        // request the current budget    
        let response = await fetch(API_URL + 'budget', {method:'GET', 
            headers: {'apikey': apikey.toString()}});
        if(!response.ok){
            // Failed to get Budget
            alert(_translate("Sorry something went wrong. The site was unable to request the current Budget."));
        } else {
            // log budget
            let data = await response.json();
            setBudget(data.budget.toString());
        }
    } 
    useEffect(() => {
        get_buget();
        // console.log("api key: " + apikey, "user id: " + userID, "username: " + username, "event: " + event)
    }, [get_buget]);

    return <div className="fill-space">
         <div className="center">
            <div className='content-container dashboard'>
                <h1 className="header1 header-dashboard">{_translate("Dashboard")}</h1>
                { /* if there is a special event this block will be added */ event !== undefined ? 
                    <div className="special-event-container">
                        <h2 className="header2 header-login special-event-header">{_translate(_getEventText(event, "dashboard", "header"))}</h2>
                        <span className="textspan special-event-textspan">{_translate(_getEventText(event, "dashboard", "textspan"))}</span>
                    </div> : null
                }
                <div className="default-dashboard-container">
                    <span className="textspan textspan-greeting">{_translate("Hello")} { username }!</span>
                    {/* <span className="textspan textspan-budget">{_translate("Your Budget is")} { convertFloatSeperationToString(budget.toString()).toString() + "€" }.</span> */}
                    <span className="textspan textspan-additionalcontent"></span>
                </div>
                <div className="tutorial-dashboard-container">
                    <h2 className="header2 tutorial-header">{_translate("First steps")}</h2>
                    <div className="step-container step-container-1">
                        <h3 className="header3 tutorial-step tutorial-step-1" onClick={() => window.location.assign("/keygen")}>{_translate("Choose your sides and get started")}</h3>
                        <span className="textspan textspan-tutorial-steps textspan-tutorial-step-1">{_translate("Use our App to get Statistics and other great features. Use this site to create and implement a Two Factor Authentification to your own website!")}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard