import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from "../../images/BerlinSMS_Symbol.svg";
import _translate from "../Globals/translation";
import cookiehandler from "../../cookies";
import { API_URL, WEBSITEVERSIONNUMBER } from "./globalVariables";


const Sidebar = (args: {logout: () => void, isLoggedIn: Boolean, accountType: "main" | "sub"}) => {
    const [apiversion, setApiversion] = useState<String | null>(null);
    const getDefaultLanguage = () => {
        let language = cookiehandler.getCookie("language");
        if(language === "") {
            language = "Ger";
            cookiehandler.setCookie("language", language, "/", window.location.hostname);
        }
        return language;
    }

    const getApiVersion = async () => {
        let response = await fetch(API_URL + 'version', {method:'GET'});
        if(!response.ok){
            alert(_translate("Sorry something went wrong. The site was unable to request the current API Version."));
        } else {
            let answer = await response.json();
            setApiversion(answer.version);
        }
    }
    
    // call once - when created
    useEffect(() => {
        getApiVersion();
     }, []);

    return <div> 
        { args.isLoggedIn ? <div className='sidebar'> 
            <img src={logo} alt="logo" className="sms-logo" onClick={() => window.location.assign("https://app.berlinsms.de")}/>
            <input type="checkbox" name="hamburger-check" className="hamburger-check"/>
            <div className="hamburger-lines">
                <span className="line line1 top"></span>
                <span className="line line2 mid"></span>
                <span className="line line3 bottom"></span>
            </div>
            <div className="sidebar_items">
                <div className="user-container">
                    <Link className="componentlink link-profile" to="/profile">{_translate("My Profile")}</Link>
                </div>
                <div className="language-container">
                    <select className="select language-select" defaultValue={getDefaultLanguage()} onChange={(event) => {cookiehandler.setCookie("language", event.target.value, "/", window.location.hostname); window.location.reload();}}>
                        <option value="Ger">&#127465;&#127466;</option>
                        <option value="Eng">&#127468;&#127463;</option>
                    </select>
                </div>
                <div className="links-container">
                    {args.accountType === "main" ? <><Link className="componentlink link-dashboard" to="/dashboard">{_translate("Dashboard")}</Link>
                    <Link className="componentlink link-keygenerator" to="/keygen">{_translate("TwoFA Sites")}</Link></> :<></>}
                    <a className="componentlink link-twofa" href="https://app.berlinsms.de" onClick={() => window.location.assign("https://app.berlinsms.de")}>{_translate("BerlinSMS App")}</a>
                    <button className="button button-logout" onClick={ args.logout }>{_translate("Logout")}</button>                     
                </div>
                <div className="versions-conatiner">
                    <span className="bottom version-website">{_translate("Webversion")}: {WEBSITEVERSIONNUMBER}</span>
                    <span className="bottom version-api">{_translate("API Version: ")}{apiversion == null ? _translate("not available") : apiversion} </span>
                </div>
            </div> 
          </div> : null
        }
    </div>
}

export default Sidebar