// ReCAPTCHA - not needded
export const REACT_APP_SITE_KEY_ReCAPTCHA = "6Lc4zdEiAAAAAP0KzpBp8ku0YSX4slR1GaH7oSeu"
// HCAPTCHA- not needded
export const REACT_APP_SITE_KEY_HCAPTCHA_old = "9a723b69-b2fc-4d49-993a-eb05254f0c5d"
export const REACT_APP_SITE_KEY_HCAPTCHA = "ff77c0f9-f527-4df2-9a3a-1c4be0db7e9c"
//BerlinSMS
export const REACT_APP_SITE_KEY_BERLINSMS = "iEEv9ICzbUCzVbywMdAHMGuTtrlmcj7TUGkmvZZXPgfEVCZSYTafWeCgFkUeeCsJ8upnY04KQjryDIZreJBz8yYIhHkzeorG43qX"
//ROUTES
export const API_URL="https://devapi.berlinsms.de/"
// Version
export const WEBSITEVERSIONNUMBER: String = "0.9.24";
