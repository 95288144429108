import React from "react";
import { Routes, Route, Navigate} from 'react-router-dom'
import Dashboard from "./components/Dashboard/Dashboard";
// import KeyGenerator from "./components/TwoFA-Sites/KeyGeneration";
import Profile from "./components/Profile/profile";

import Login from "./components/Login/login";
import Register from "./components/Login/register";
import TwoFASite from "./components/TwoFA-Sites/twoFA-Site";
// Route war vorher für default gedacht -> leitet aber jetzt direkt aufs Dashboard weiter

interface RoutingParameters {
    isLoggedin: Boolean;
    apikey: string;
    username: string;
    userID: string;
    fullName: string;
    accountType: "sub" | "main";
    validateLogin: () => void;
}

const MainRoutes: React.FC<RoutingParameters> = ({apikey, username, userID, fullName, isLoggedin, accountType, validateLogin}) => (
    // if user is not logged in redirect to login
    // if user is logged in redirect to dashboard 
    isLoggedin ? 
    <Routes>
        { accountType === "main" ? <>
            <Route path="*" element={<Navigate replace to="/dashboard"/>}/>
            <Route path="/" element={<Navigate replace to="/dashboard"/>}/> </> : <>
            <Route path="*" element={<Navigate replace to="/keygen"/>}/>
            <Route path="/" element={<Navigate replace to="/keygen"/>}/></>}
        <Route path="/profile" element={<Profile apikey={apikey} userID={userID} username={username} fullName={fullName} accountType={accountType}/>}/>
        <Route path="/dashboard" element={<Dashboard apikey={apikey} userID={userID} username={username} fullName={fullName} accountType={accountType} event={undefined}/>}/>
        <Route path="/keygen" element={<TwoFASite apikey={apikey} userID={userID}/>}/>
        {/*  Special Event Routes  */}
        <Route path="/login-ostern23" element={<Navigate replace to="/dashboard-ostern23"/>}/>
        <Route path="/register-ostern23" element={<Navigate replace to="/dashboard-ostern23"/>}/>
    </Routes>
    :
    <Routes>
        <Route path="*" element={<Login validateLogin={validateLogin}  event={undefined}/>}/>
        <Route path="/" element={<Navigate replace to="/login"/>}/>
        <Route path="/login" element={<Login validateLogin={validateLogin} event={undefined}/>}/>
        <Route path="/register" element={<Register event={undefined}/>}/>
        {/* Special Event Routes */}
        <Route path="/login-ostern23" element={<Login validateLogin={validateLogin} event="ostern23"/>}/>
        <Route path="/register-ostern23" element={<Register event="ostern23"/>}/>
    </Routes>
)

export default MainRoutes