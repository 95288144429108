import React, {useEffect, useRef, useState} from "react";
import _translate from "../Globals/translation";
import {twofaSite} from "./twoFA-Variables";

interface twoFAHeaderParameters {
    apikey: String;
    userID: String;
    twofaSite: twofaSite;
    saveSite: (site: twofaSite) => Promise<boolean>;
}

const TwoFAHeader:React.FC<twoFAHeaderParameters> = ({apikey, userID, twofaSite, saveSite}) => {
    // Refs
    const labelRef = useRef<HTMLInputElement>(null);
    const twofaTypeRef = useRef<HTMLSelectElement>(null);
    const twofaSitekeyRef = useRef<HTMLInputElement>(null);
    const twofaSecretkeyRef = useRef<HTMLInputElement>(null);
    const captchaTypeRef = useRef<HTMLSelectElement>(null);
    const captchaSitekeyRef = useRef<HTMLInputElement>(null);
    const captchaSecretkeyRef = useRef<HTMLInputElement>(null);
    const feedbackLabelRef = useRef<HTMLLabelElement>(null);
    const feedbackDivRef = useRef<HTMLDivElement>(null);
    const domainsRef = useRef<HTMLDivElement>(null);
    
    // States
    const [domains, setDomains] = useState<Array<string>>([]);
    const [domainsRerender, setDomainsRerender] = useState<boolean>(false); // used to rerender the domains
    const [initalizingDomains, setInitalizingDomains] = useState<boolean>(true);
    const [currentSite, setCurrentSite] = useState<twofaSite>(twofaSite);
    
    useEffect(() => {
        // if the site changes, update the current site
        labelRef.current!.value = twofaSite.twofaLabel;
        twofaTypeRef.current!.value = twofaSite.twofaType;
        twofaSitekeyRef.current!.value = twofaSite.twofaSitekey;
        twofaSecretkeyRef.current!.value = twofaSite.twofaSecretkey;
        captchaTypeRef.current!.value = twofaSite.captchaType;
        captchaSitekeyRef.current!.value = twofaSite.captchaSitekey;
        captchaSecretkeyRef.current!.value = twofaSite.captchaSecretkey;
        setDomains([...twofaSite.domains, ""]);
        setDomainsRerender(!domainsRerender);
        setCurrentSite(twofaSite);
        if(twofaSite.id !== currentSite.id){
            feedbackDivRef.current!.className="twoFA-content-container twoFA-content-container-feedback hidden";
        } else{
            feedbackDivRef.current!.className="twoFA-content-container twoFA-content-container-feedback";
        } 
    }, [twofaSite]);

    // add a new empty domain entry if the last one is not empty
    useEffect(() => {
        if(initalizingDomains){
            setInitalizingDomains(false);
            feedbackDivRef.current!.className="twoFA-content-container twoFA-content-container-feedback hidden";
            return;
        }
        if(domains.length > 0){
            const _lastDomain = domains[domains.length - 1];
            if(_lastDomain !== ""){
                setDomains([...domains, ""])
                setCurrentSite({...currentSite, domains: [...domains, ""]});
            }
        } else {
            setDomains([""]);
            setCurrentSite({...currentSite, domains: [""]});
        }

    }, [domains]);

    // remove the last domain entry
    const removeDomainEntry = (index: number) => {
        var domainsCopy = [...domains];    
        domainsCopy.splice(index, 1);
        // force the map to rerender
        setDomains([...domainsCopy]);
        setCurrentSite({...currentSite, domains: [...domainsCopy]});
        setDomainsRerender(!domainsRerender);
    }
    // update the domain entry
    const onInputDomain = (e: React.FormEvent<HTMLInputElement>) => {
        var _domains = [...domains];
        const index = e.currentTarget.getAttribute("data-key");         
        if(index !== null){
            _domains[parseInt(index)] = e.currentTarget.value;
            setDomains(_domains);
            setCurrentSite({...currentSite, domains: _domains});
        }
    }

    // update the current site object when the input changes
    // these functions keep track of any changes and enable the save button and feedback and reset button   
    const twofaOnChangeLabel = (e:  React.FormEvent<HTMLInputElement>) => {
        setCurrentSite({...currentSite, twofaLabel: e.currentTarget.value});
    }
    const twofaOnChangeType = (e:  React.FormEvent<HTMLSelectElement>) => {
        setCurrentSite({...currentSite, twofaType: e.currentTarget.value});
    }
    const twofaOnChangeCaptchaSitekey = (e:  React.FormEvent<HTMLInputElement>) => {
        setCurrentSite({...currentSite, captchaSitekey: e.currentTarget.value});
    }
    const twofaOnChangeCaptchaSecretkey = (e:  React.FormEvent<HTMLInputElement>) => {
        setCurrentSite({...currentSite, captchaSecretkey: e.currentTarget.value});
    }
    const twofaOnChangeCaptchaType = (e:  React.FormEvent<HTMLSelectElement>) => {
        setCurrentSite({...currentSite, captchaType: e.currentTarget.value});
    }       

    // checks if the inputs are valid and sends the current site to the parent component
    const saveCurrentState = async () => {
        if(currentSite.twofaLabel === null || currentSite.twofaLabel === undefined || currentSite.twofaLabel === ""){
            alert(_translate("Please enter a label for the site"));
            return;
        } else if(currentSite.twofaType === null || currentSite.twofaType === undefined || currentSite.twofaType === ""){
            alert(_translate("Please select a 2FA type"));
            return;
        } else if(currentSite.captchaSitekey === null || currentSite.captchaSitekey === undefined || currentSite.captchaSitekey === ""){
            alert(_translate("Please enter a Captcha sitekey"));
            return;
        } else if(currentSite.captchaSecretkey === null || currentSite.captchaSecretkey === undefined || currentSite.captchaSecretkey === ""){
            alert(_translate("Please enter a Captcha secretkey"));
            return;
        } else if(currentSite.captchaType === null || currentSite.captchaType === undefined || currentSite.captchaType === ""){
            alert(_translate("Please select a Captcha type"));
            return;
        } else if(currentSite.domains.filter(d => d !== "").length === 0){
            alert(_translate("Please enter at least one domain"));
            return;
        }
        const _twofaSite: twofaSite = {
            id: twofaSite.id,
            twofaLabel: labelRef.current!.value,
            domains: domains.filter(d => d !== ""), // remove empty domains
            twofaType: twofaTypeRef.current!.value,
            twofaSitekey: twofaSitekeyRef.current!.value,
            twofaSecretkey: twofaSecretkeyRef.current!.value,
            captchaType: captchaTypeRef.current!.value,
            captchaSitekey: captchaSitekeyRef.current!.value,
            captchaSecretkey: captchaSecretkeyRef.current!.value
        }
        const worked = await saveSite(_twofaSite);      
        feedbackDivRef.current!.className = "twoFA-content-container twoFA-content-container-feedback";
        if(worked){
            feedbackLabelRef.current!.innerText = _translate("Your current websiteconfiguration got saved!").toString();
        } else {
            feedbackLabelRef.current!.innerText = _translate("Error while saving. Please try again later.").toString();
        } 
    }

    const resetCurrentState = () => {
        // set all refs to the current state
        labelRef.current!.value = twofaSite.twofaLabel;
        twofaTypeRef.current!.value = twofaSite.twofaType;
        twofaSitekeyRef.current!.value = twofaSite.twofaSitekey;
        twofaSecretkeyRef.current!.value = twofaSite.twofaSecretkey;
        captchaTypeRef.current!.value = twofaSite.captchaType;
        captchaSitekeyRef.current!.value = twofaSite.captchaSitekey;
        captchaSecretkeyRef.current!.value = twofaSite.captchaSecretkey;
        setDomains([...twofaSite.domains, ""]);
        setDomainsRerender(!domainsRerender);
    }
    
    const copyToClipboard= (item: string) => {
        navigator.clipboard.writeText(item);
    }


    return <div className="twoFA-inner-content-container">
            <div className="twoFA-content-container twoFA-content-container-name">
                <label className="descr-label  label-twoFA-content-name">{_translate("Label")}</label>
                <input ref={labelRef} className="inputfield input-twoFA-content-name" type="text" onChange={twofaOnChangeLabel} placeholder={_translate("My Websites Label").toString()}></input>
            </div>
            <div className="twoFA-content-container twoFA-content-container-twofaType">
                <label className="descr-label label-twoFA-content-twofaType">{_translate("TwoFA Type")}</label>
                <select ref={twofaTypeRef} className="selectionelement select-twofatype" onChange={twofaOnChangeType}>
                    <option className="twofatype-option" value="optin(sms)">{_translate("Optin (SMS)")}</option>
                    <option className="twofatype-option" value="optin(mail)">{_translate("Optin (Mail)")}</option>
                    <option className="twofatype-option" value="optin(mail+sms)" disabled>{_translate("Optin (Mail + SMS)")}</option>
                    <option className="twofatype-option" value="sms-auth" disabled>{_translate("TwoFA (SMS)")}</option>
                    <option className="twofatype-option" value="mail-auth" disabled>{_translate("TwoFA (Mail)")}</option>
                    <option className="twofatype-option" value="oauth2" disabled>{_translate("TwoFA (OAuth2)")}</option>
                </select>
            </div>
            <div className="twoFA-content-container twoFA-content-container-captchaKeys">
                <span className="textspan span-twoFA-content-captchaKeys">{_translate("Captcha Keys")}</span>
                <div className="twoFA-content-container2 twoFA-content-container-captchatype">
                    <label className="descr-label label-twoFA-content-captchaType">{_translate("Captcha Type")}</label>
                    <select ref={captchaTypeRef} className="selectionelement select-captchatype" onChange={twofaOnChangeCaptchaType}>
                        <option className="captchatype-option" value="reCaptcha">{_translate("ReCaptcha")}</option>
                        <option className="captchatype-option" value="hCaptcha">{_translate("HCaptcha")}</option>
                    </select>
                </div>
                <div className="twoFA-content-container2 twoFA-content-container-captchaSitekey">
                    <label className="descr-label label-twoFA-content-captcha-sitekey">{_translate("Sitekey")}</label>
                    <input ref={captchaSitekeyRef} onChange={twofaOnChangeCaptchaSitekey} className="inputfield input-twoFA-content-captcha-sitekey" type="text" placeholder={_translate("Sitekey").toString()}/>            
                </div>
                <div className="twoFA-content-container2 twoFA-content-container-captchaSecretkey">
                    <label className="descr-label label-twoFA-content-captcha-secretkey">{_translate("Secretkey")}</label>
                    <input ref={captchaSecretkeyRef} onChange={twofaOnChangeCaptchaSecretkey} className="inputfield input-twoFA-content-captcha-secretkey" type="text" placeholder={_translate("Secretkey").toString()}/>
                </div>
            </div>
            <div className="twoFA-content-container twoFA-content-container-twoFAKeys">
                <span className="textspan span-twoFA-content-twoKeys">{_translate("TwoFA Keys")}</span>
                <div className="twoFA-content-container2 twoFA-content-container-twoFASitekey">
                    <label className="descr-label label-twoFA-content-twoFA-sitekey">{_translate("Sitekey")}</label>
                    <input ref={twofaSitekeyRef} className="inputfield input-twoFA-content-twoFA-sitekey" type="text" placeholder={_translate("Sitekey").toString()} disabled/>            
                    <button title="copy" className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(twofaSitekeyRef.current!.value)}>&#128203;</button>
                </div>
                <div className="twoFA-content-container2 twoFA-content-container-twoFASecretkey">
                    <label className="descr-label label-twoFA-content-twoFA-secretkey">{_translate("Secretkey")}</label>
                    <input ref={twofaSecretkeyRef} className="inputfield input-twoFA-content-twoFA-secretkey" type="text" placeholder={_translate("Secretkey").toString()} disabled/>
                    <button title="copy" className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(twofaSecretkeyRef.current!.value)}>&#128203;</button>
                </div>  
            </div>
            <div className="twoFA-content-container twoFA-content-container-domains">
                <label className="descr-label label-twoFA-content-domains">{_translate("Domains")}</label>
                <div ref={domainsRef} className="input-twoFA-content-domains-container">
                    {domains.map((domain, index) => {
                        return (
                            <div className="inputcontainer input-twoFA-content-domains-container" key={"div-domain-input"+index+domainsRerender}>
                                <input className="inputfield input-twoFA-content-domains" type="text" defaultValue={domain} placeholder={_translate("www.exampledomain.com").toString()} data-key={index} key={"input-domain-input"+index} onChange={onInputDomain} />
                                {domain!=="" ? <button title={"delete"} className="button button-twoFA-domains-delete" key={"button-domain-input"+index} onClick={() => removeDomainEntry(index)}>&#10060;</button> : <></>}
                            </div>
                        );
                    })}
                </div>
                {/* <button className="button button-add-domain" onClick={addDomainEntry}>{_translate("Add Domain")}</button> */}
                {/* <button className="button button-remove-domain" onClick={removeDomainEntry}>{_translate("Remove Domain")}</button> */}
            </div>
            <div ref={feedbackDivRef} className="twoFA-content-container twoFA-content-container-feedback hidden">
                <label ref={feedbackLabelRef} className="descr-label feedback-label"></label>
            </div>
            {JSON.stringify(twofaSite) !== JSON.stringify(currentSite) ? <div className="twoFA-content-container twoFA-content-container-buttons">
                <button className="button twofa-button-save" onClick={saveCurrentState}>{_translate("Save this configuration")}</button>
                <button className="button twofa-button-reset" onClick={resetCurrentState}>{_translate("Reset all recend configuration changes")}</button>
            </div> : <></>}
        </div>
}

export default TwoFAHeader