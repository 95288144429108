import React, {useEffect, useState} from 'react';

// components and handler classes
import MainRoutes from './Routes';
import Sidebar  from './components/Globals/Sidebar';
import cookiehandler from './cookies';

// styles
import "./assets/Globals_styles.css";
import "./assets/DashBoard_styles.css";
import "./assets/Login_styles.css";
import "./assets/fcm_styles.css";
import "./assets/twoFAHeader_styles.css";
import "./assets/twoFAEditSite_styles.css";
import "./assets/TwoFAComponent_styles.css";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);
    const [token, setToken] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [userID, setUserID] = useState<string>("");
    const [accountType, setAccountType] = useState<"sub" | "main">("main"); // ["sub", "main"]

    // check if there is a api key to skip the login
    useEffect(() => {
        validateLogin();
    }, []);

    function logout() {
        setIsLoggedIn(false);
        // delete cookie to prevent automatic login
        cookiehandler.delete_cookie("apikey", "/", window.location.hostname);
        // cookiehandler.delete_cookie("uid", "/", window.location.hostname);
    }
    function validateLogin() {
        // check if api token exists 
        var _token: string = cookiehandler.getCookie("apikey");
        // var _uid: string = cookiehandler.getCookie("uid");
        // var _accountType: string = cookiehandler.getCookie("accountType");
        // check if token is valid -> if valid set _token
        if(!(_token === undefined || _token === "")){
            cookiehandler.validate_ApiTokenUid(_token, 
                (_isLoggedIn: Boolean) => setIsLoggedIn(_isLoggedIn),
                (_token: string) => setToken(_token),
                (_userID: string) => setUserID(_userID),
                (_username: string) => setUsername(_username),
                (_accountType: "sub" | "main") => setAccountType(_accountType),
                (_fullName: string) => setFullName(_fullName));
            ;
            // setAccountType("sub"); // testing sub account
        }
    }

    return (  
        <div className="App">
            {/**  Sidebar **/}
            <Sidebar        logout={() => logout()}
                            isLoggedIn={isLoggedIn}
                            accountType={accountType}/>

            {/**  inner content **/}
            <MainRoutes     username={username} 
                            fullName={fullName}
                            apikey={token} 
                            userID={userID} 
                            isLoggedin={isLoggedIn}
                            accountType={accountType}
                            validateLogin={() => validateLogin()}/>
        </div>
    );
}

export default App;
