// using usestates to save the user input
import React, {useState, useRef} from "react";
import _translate, { _getEventText } from "../Globals/translation";

/* ---- IMPORTANT ---- */
// GOOGLE 
// npm i --save react-google-recaptcha
// if import is not found types are needed: npm i --save-dev @types/react-google-recaptcha 
// HCAPTCHA
// npm install --save hcaptcha
// use import needed 
// import TwoFA from "../TwoFA-Component/TwoFA"; 
import { TwoFA } from "@berlinsms/react-twofa-component";
import { Link } from "react-router-dom";
import { API_URL, REACT_APP_SITE_KEY_BERLINSMS } from "../Globals/globalVariables";

interface registerParameters {
    event: string | undefined;
}

const Register_ReCaptcha: React.FC<registerParameters> = ({event}) => { 
    const [username, setUsername] = useState<string>(""); // username is the email address 
    const [password, setPassword] = useState<string>("");
    const [fullname, setFullname] = useState<string>("");
    const [phonenumber, setPhonenumber] = useState<string|undefined>("");
    const buttonRef = useRef<HTMLButtonElement>(null);

    // TWOFA Vars
    const twoFARef = useRef<TwoFA>(null);
    const [challengeToken, setChallengeToken] = useState<string|undefined>("");
    const [validationCode, setValidationCode] = useState<string|undefined>("");

    const register_sender = async () => {
        // request current login based on login data
        let response = await fetch(API_URL + 'register', 
            {
                method:'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password:     password,
                    challengeToken: challengeToken,
                    userName:     username,
                    fullName:     fullname,
                    phonenumber:  phonenumber,
                })
            }
        );
        if(!response.ok){
            console.warn("Register failed");
            // let data = await response.text();
            if(response.status === 403) alert(_translate("Register failed - check your credentials.") + "\nError: Bad Request");
            else if(response.status === 409) alert(_translate("Register failed - phonenumber already exists."));
            else if(response.status === 404) alert(_translate("Register failed - check your credentials.") + "\nError: Not Found");
            else if(response.status === 500) alert(_translate("Register failed - heck your credentials or contact the website admin.") + "\nError: Internal Server Error");
            else alert(_translate("Register failed - check your credentials or contact the website admin.") + "\nError: " + response.status);
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
        } else {
            //let data = await response.json();
            alert(_translate("Registration successful. You will be redirected to the login page. Please login with your new credentials."));
            window.location.href = "/login?signup-completed";
        }
    } 

    const onRegisterClick = () => {
        // disable button
        if(buttonRef.current !== null) buttonRef.current.disabled = true;
        if(phonenumber === undefined || phonenumber === "" || phonenumber.length < 9 || phonenumber.length > 22){
            alert(_translate("Please enter and verify your phone number"));
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
            return;
        } else if(password === undefined || password === "" || password.length < 8){
            alert(_translate("Please enter a password with at least 8 characters"));
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
            return;
        } else if(username === undefined || username === "" || username.length < 3 || !validateEmail(username)){
            alert(_translate("Please enter a username") + " (E-Mail)");
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
            return;
        } else if(fullname === undefined || fullname === "" || fullname.split(" ").length < 2){
            alert(_translate("Please enter your full name (first name and last name, separated by a space)"));
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
            return;
        } else if(challengeToken === undefined || challengeToken === ""){
            alert(_translate("Please verify your phone number"));
            if(buttonRef.current !== null) buttonRef.current.disabled = false;
            return;
        } else if(validationCode === undefined || validationCode === ""){
            alert(_translate("Please verify your phone number"));
            if(buttonRef.current !== null) buttonRef.current.disabled = false;  
            return;
        }
        // IF ALL IS OKAY SEND REGISTER REQUEST
        register_sender();
        // redirect to login
    }
    const validateEmail = (email: string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };
    
    /* ---- TWO FACTOR AUTHENTIFICATION BY BERLINSMS ---- */
    const TwoFAonVerify = (token: string, code?: string) => {
        // console.log("TwoFA verified");
        setPhonenumber(twoFARef.current?.state.verificationAddress);
        setChallengeToken(token);
        setValidationCode(code);
    }
    const TwoFAonExpire = () => {
        console.warn("TwoFA expired");
        twoFARef.current?.reset();
    }
    const TwoFAonError = (error: string) => {
        console.warn("TwoFA error: " + error);
    }

    return <div className="fill-space">
        <div className="center">
            {
                <div className="register">
                    <h1 className="header1 header-register">{_translate("Register")}</h1>
                    { /* if there is a special event this block will be added */ event !== undefined ? 
                        <div className="special-event-container">
                            <h2 className="header2 header-login special-event-header">{_translate(_getEventText(event, "register", "header"))}</h2>
                            <span className="textspan special-event-textspan">{_translate(_getEventText(event, "register", "textspan"))}</span>
                        </div> : null
                    }
                    {/* Inputs */}
                    <div className="register-email-container">
                        <label className="textlabel email-description">{_translate("E-Mail / Username")}</label>
                        <input className="inputfield input-email" onInput={e => setUsername((e.target as HTMLInputElement).value)} required placeholder="max@mustermail.de"/>
                    </div>
                    <div className="register-name-container">
                        <label className="textlabel username-description">{_translate("Full name")}</label>
                        <input className="inputfield input-email" onInput={e => setFullname((e.target as HTMLInputElement).value)} required placeholder="Max Mustermann"/>
                    </div>
                    <div className="register-password-container">
                        <span className="textlabel password-description">{_translate("Password - min. 8 characters")}</span>
                        <input className="inputfield input-password" type="password" onInput={e => setPassword((e.target as HTMLInputElement).value)} required placeholder="********"/>
                    </div>
                    {/* TwoFA BerlinSMS - What happens here: 
                    - bsmssitekey: BerlinSMS sitekey provided by BerlinSMS
                    - onVerify: gets called when 2FA was correctly completed 
                    - onExpire: gets called when 2FA expired (e.g. run out of tries)
                    - sometimes gets called together with onError
                    - onError: gets called when 2FA failed or something unexpected happened
                    */}
                    <TwoFA
                        ref={twoFARef}                    
                        bsmsSitekey = {REACT_APP_SITE_KEY_BERLINSMS}
                        onError = {TwoFAonError}
                        onVerify = {TwoFAonVerify}
                        onExpire = {TwoFAonExpire}
                    />
                    {/* ---- IMPORTANT - change token to the desired captcha ---- */}
                    <div className="register-button-container">
                        <button ref={buttonRef} className="button submitbutton button-sms-register" onClick={() => onRegisterClick()} disabled={!challengeToken}>{_translate("Register")}</button>
                        {/* props has an useState from Login to check  if the registration is active or not */}
                        <Link className="componentlink link-register" to={event!==undefined && event!==null && event !== "" ? "/login-" + event : "/login"}>{_translate("Already got a login? - Click here!")}</Link>
                    </div>
                </div>}
            </div>
        </div>
}

export default Register_ReCaptcha
