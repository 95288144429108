import React, { useEffect, useState } from "react";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";
import { fixedEncodeURIComponent } from "../Globals/globalFunctions";

interface ProfileParameters {
    apikey: string;
    username: string;
    fullName: string;
    userID: string;
    accountType: "sub" | "main";
}
interface AccountReduced {
    tag: string,
    password: string,
}

const Profile: React.FC<ProfileParameters> = ({apikey, userID, username, fullName, accountType}) => {
    const [account, setAccount] = useState<AccountReduced>({password: "", tag: ""});
    const [editPassword, setEditPassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false); // [true, false
    const passwordInputtRef = React.useRef<HTMLInputElement>(null);
    const passwordButtonRef = React.useRef<HTMLButtonElement>(null);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    useEffect(() => {
        if(passwordInputtRef.current !== null && passwordButtonRef.current !== null){
            if(showPassword){
                passwordInputtRef.current.type = "text";
                passwordButtonRef.current.textContent = _translate("Hide").toString();
            } else {
                passwordInputtRef.current.type = "password";
                passwordButtonRef.current.textContent = _translate("Show").toString();
            }
        }
    }, [showPassword]);


    const getAccountInfo = async () => {
        // console.log("userID: " + userID);
        let response = await fetch(API_URL + 'user/'+userID,  {method:'GET', 
            headers: {'apikey': apikey.toString()}});
        if(!response.ok){
            console.warn("No Account found");
        } else {
            let data = await response.json();
            setAccount(
                {
                    password: data.user.password,
                    tag: data.user.tag,
                }
            );   
        }
    }

    const enableEdit = () => {
        setEditPassword(!editPassword);
        setShowPassword(true);
        if(passwordInputtRef.current !== null) passwordInputtRef.current.disabled = false;
    }
    const cancelPasswordEdit = () => {
        getAccountInfo();
        setShowPassword(false);
        if(passwordInputtRef.current !== null) passwordInputtRef.current.disabled = true;
        setEditPassword(!editPassword);

    }
    const saveNewPassword = async () => {
        // setting the edit to false and disabling the inputs
        setEditPassword(false);
        if(passwordInputtRef.current !== null) passwordInputtRef.current.disabled = true;
        // check if the tag is empty
        if(account.tag === "" || account.tag === undefined || account.tag === null) {
            window.alert(_translate("Tag can not be empty"));
            return;
        }
        // check if the password is empty
        if(account.password === "" || account.password === undefined || account.password === null ||
            account.password.length < 8 || account.password.length > 64 ||
            // check if there is a uppercase letter, a lowercase letter and a special character
            !account.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,64}$/)) {
            window.alert(_translate("Password can not be empty. A password must contain at least 8 characters, one uppercase letter and one special character"));
            return;
        }        
        // sending the request
        // encode the password and tag so that the url is valid and the request can be send
        let response = await fetch(API_URL + 'user/'+userID+"?"+"tag="+fixedEncodeURIComponent(account.tag)+"&"+"password="+fixedEncodeURIComponent(account.password), 
            {method:'PUT', 
            headers: {'apikey': apikey.toString(), 'Content-Type': 'application/json'},
            body: JSON.stringify({tag: account.tag, password: account.password})
        });
        if(!response.ok){
            console.warn("Saving Account failed");
            let data = await response.json();
            window.alert("Saving Account failed! Errorcode: " + data.error);
        } else {
            console.log("Account saved");
            window.alert(_translate("Account saved"));
        }
    }


    useEffect(() => {
        getAccountInfo();
    }, []);

    return <div className="fill-space">
        <div className="center">
            <div className='content-container dashboard'>
                { /* display all Profile Information */ }
                <h1 className="header1 header-dashboard">{_translate("Profile")}</h1>
                <div className="profile-container">
                    <div className="profile-container profile-container-username">
                        <span className="textspan profile-textspan profile-textspan-username">{_translate("Username")}:</span>
                        <input className="inputt profile-input profile-input-username2" disabled value={username}/>
                    </div>
                    <div className="profile-container profile-container-accounttype">
                        <span className="textspan profile-textspan profile-textspan-accounttype">{_translate("Account Type")}:</span>
                        <input className="inputt profile-input profile-input-accounttype2" disabled value={accountType}/>
                    </div>
                    <div className="profile-container profile-container-apikey">
                        <span className="textspan profile-textspan profile-textspan-apikey">{_translate("API-Key")}:</span>
                        <input className="inputt profile-input profile-input-apikey2" disabled value={apikey}/>
                    </div>
                    <div className="profile-container profile-container-password">
                        <span className="textspan profile-textspan profile-textspan-password">{_translate("Password")}:</span>
                        <input className="inputt profile-input profile-input-password" ref={passwordInputtRef} onInput={(e: React.ChangeEvent< HTMLInputElement>) => setAccount({...account, password: e.target.value})} type="password" disabled value={account.password}/>
                        <button className="button profile-button profile-button-password" ref={passwordButtonRef} onClick={togglePasswordVisibility}>{_translate("Show")}</button>
                        { !editPassword ? 
                            <button className="button profile-button profile-button-password" onClick={enableEdit}>{_translate("Edit")}</button> : <>
                            <button className="button profile-button profile-button-password" onClick={saveNewPassword}>{_translate("Save")}</button>
                            <button className="button profile-button profile-button-password" onClick={cancelPasswordEdit}>{_translate("Cancel")}</button>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Profile