import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'

// removed strict mode because of rendering issues
// before it was:
//    <React.StrictMode>
//      <BrowserRouter>
//        <App />            
//      </BrowserRouter> 
//    </React.StrictMode>
// caused double rendering of all components

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <App />            
    </BrowserRouter>
  
);